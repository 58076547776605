.login {
  margin-top: 12.5%;
  .brand {
    text-align: center;
    font-size: 40px;
    font-weight: 100;
    margin-bottom: 20px;
  }
  .btn {
    margin-bottom: 20px;
  }
}