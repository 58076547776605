.file-uploader {
  margin-bottom: @margin-default;


  .file-deleted { text-decoration: line-through; color: @gray-light; }

  .file-list .progress { margin: 5px 0px 0px 0px; }
  .file-list .cancel, .file-list .delete, .file-list .retry { margin: 0px 5px 0px 0px; display: inline; border:0px; background: transparent; padding:0px; }
  .file-list .list-group { .list-group-item { font-size: @font-size-small; } margin-bottom: 0px;}

  .main-progress-bar { margin:@margin-default 0 0 0; }
}

.dropzone {
    background-color: lighten(@gray-lighter, 5%);
    padding: @padding-default;
    border-radius: @border-radius-base;
    text-align: center;
    font-size: @font-size-h4;
    color: @gray-light;
    margin-bottom: 0px;
    border: 2px dotted @gray-lighter;
    cursor: pointer;
    &:hover {
      color: @brand-info;
      border-color: @brand-info;
      background-color: lighten(@brand-info, 35%);
    }
    &:active {
      color: @brand-info;
      border-color: @brand-info;
      background-color: lighten(@brand-info, 30%);
    }
    &.success {
      color: @brand-success;
      border-color: @brand-success;
      background-color: lighten(@brand-success, 35%);
    }
    &.danger {
      color: @brand-danger;
      border-color: @brand-danger;
      background-color: lighten(@brand-danger, 35%);
    }
}