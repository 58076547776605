.wizard-buttons {
  margin-top: 10px;
}

.margin-button-left {
  float: left;
}

.margin-button-right {
  float: right;
}

