@info-text: lighten(#000, 46.7%);

.comments-header {
  .comments-info {
    font-size: 12px;
    color: @info-text;
  }
}

.new-comment {
  textarea {
    resize: none;
  }
  button {
    margin-top: 10px;
    float: right;
  }
}

.edit-comment {
  overflow: auto;
  textarea {
    margin-top: 10px;
    resize: none;
  }
  button {
    margin-top: 10px;
    float: right;
  }
}
