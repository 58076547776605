
// Small progress Bar
.progress-sm {
  height: @margin-default/3;
  font-size: @font-size-small;
}

// Overwriting Bootstrap components
.progress {
  -webkit-box-shadow: none;
  box-shadow: none;
}

// inputs range
.input-range-wrapper {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  input.input-range {
    border: 2px solid black;
  }
}
.input-group {
  .input-range-wrapper:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-range-wrapper:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}


// Input group with splitted inputs
.input-group.all-inputs {
  & input:first-child {
    width:50%;
    float:left;
  }
  & input:last-child {
    width:50%;
    float:right;
    border-left: 0px;
  }
}

// labels
.label {
  font-weight: normal;
  &.label-lg {
    font-size: @font-size-base;
    padding: .2em .6em .3em;
  }
}

// Main menu
.main-menu {
  .navbar-inverse {
    color: #ffffff;
    background-color: rgb(25, 25, 25);
    background-color: rgba(25, 25, 25, 0.9);
    border-color: rgb(25, 25, 25);
    border-color: rgba(25, 25, 25, 0.9);
    .navbar-brand, .navbar-nav > li > a {
      color: #aaaaaa;
      &:hover {
        color: #ffffff;
      }
      &:active {
        background: #000000;
      }
    }
  }
  .dropdown-menu {
    color: #000000;
  }
  li.dropdown:hover ul.dropdown-menu {
    display: block;
  }
  .navbar-icons a {
    padding-left: 15px;
    padding-right: 15px;
  }
}


// tabs
.tab-content {
  background-color: #ffffff;
  padding: @padding-default;
  border: 1px solid @nav-tabs-border-color;
  border-top: 0px;
}