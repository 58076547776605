ul[dnd-list], ul[dnd-list] > li {
  position: relative;
}

.header-wrapper {
  > span {
    opacity: 0;
    width: 5%;
    font-size: 20px;
    display: inline-block;
    vertical-align: super;
    padding-right: 5px;
  }
  > div {
    width: 90%;
    display: inline-block;
  }
  .header-label {
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    margin: 0px;
  }
}

.header-divider {
  margin: 0px;
}

ul[dnd-list] {
  padding: 0px;
  li {
    background-color: #fff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
    padding: 10px 0px;
    margin-bottom: -1px;
    cursor: ns-resize;
    > div {
      width: 100%;
      display: inline-block;
    }
  }
}

.wrapper-sortable {
  > .icon-move {
    > span {
      font-size: 20px;
      vertical-align: -webkit-baseline-middle;
      padding-right: 5px;
    }
    width: 5%;
    float: left;
  }

  > .row {
    width: 90%;
    float: left;
    margin-right: 0px;
    margin-left: 0px;
  }

  > .icon-delete {
    width: 5%;
    float: left;
    > span {
      font-size: 20px;
      vertical-align: -webkit-baseline-middle;
      padding-right: 5px;
      cursor: pointer;
    }
  }
}

.new-invoice-line {
  background-color: #fff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: block;
  padding: 10px 0px;
  margin-bottom: -1px;
  > div {
    width: 100%;
    display: inline-block;
    .icon-move {
      opacity: 0;
    }
    .icon-add {
      font-size: 20px;
      vertical-align: -webkit-baseline-middle;
      padding-right: 5px;
      cursor: pointer;
    }
  }
}

.new-invoice-divider {
  margin-top: -9px;
}