/* line 16, ../src/ADM-dateTimePicker.scss */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* line 20, ../src/ADM-dateTimePicker.scss */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 28, ../src/ADM-dateTimePicker.scss */
a:active,
a:hover {
  outline: 0;
}

/* line 33, ../src/ADM-dateTimePicker.scss */
.dtp-trs-3 {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/* line 36, ../src/ADM-dateTimePicker.scss */
.dtp-trs-5 {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* line 40, ../src/ADM-dateTimePicker.scss */
.dtp-i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  vertical-align: top;
  position: relative;
  display: inline-block;
  fill: inherit;
}
/* line 48, ../src/ADM-dateTimePicker.scss */
.dtp-i.dtp-i-90 {
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
/* line 51, ../src/ADM-dateTimePicker.scss */
.dtp-i.dtp-i-180 {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* line 54, ../src/ADM-dateTimePicker.scss */
.dtp-i.dtp-i-270 {
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

/* line 59, ../src/ADM-dateTimePicker.scss */
.dtp-ig {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
/* line 80, ../src/ADM-dateTimePicker.scss */
.dtp-ig .fakeIcon {
  opacity: 0;
  height: 10px;
  width: 10px;
}
/* line 85, ../src/ADM-dateTimePicker.scss */
.dtp-ig .calendarIcon,
.dtp-ig .removeIcon,
.dtp-ig .closeIcon {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  padding: 0px 8px;
  padding-top: 2px;
  -moz-transition: all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -o-transition: all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -webkit-transition: all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  transition: all 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* line 99, ../src/ADM-dateTimePicker.scss */
.dtp-ig .calendarIcon {
  fill: #606060;
}
/* line 102, ../src/ADM-dateTimePicker.scss */
.dtp-ig .closeIcon {
  background: #378bd3;
  fill: white;
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* line 109, ../src/ADM-dateTimePicker.scss */
.ADMdtp-calendar-container.rtl {
  font-family: Tahoma;
  direction: rtl;
}

/* line 114, ../src/ADM-dateTimePicker.scss */
.ADMdtp {
  font-family: sans-serif, sans-serif;
  line-height: 1.42857143;
  color: #797979;
}
/* line 119, ../src/ADM-dateTimePicker.scss */
.ADMdtp.rtl {
  font-family: Tahoma;
}
/* line 122, ../src/ADM-dateTimePicker.scss */
.ADMdtp.rtl .masterInput input {
  direction: ltr;
}
/* line 127, ../src/ADM-dateTimePicker.scss */
.ADMdtp ng-transclude,
.ADMdtp .clickOutContainer {
  position: relative;
  display: block;
}
/* line 133, ../src/ADM-dateTimePicker.scss */
.ADMdtp hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

/* line 147, ../src/ADM-dateTimePicker.scss */
.dtpNewBox {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  background: #f7f7f7;
  top: 0;
  left: 0;
  z-index: 9;
  direction: ltr;
}
/* line 159, ../src/ADM-dateTimePicker.scss */
.dtpNewBox.active {
  opacity: 1;
  height: 20em;
}
/* line 164, ../src/ADM-dateTimePicker.scss */
.dtpNewBox.active .timeSelectIcon {
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
/* line 167, ../src/ADM-dateTimePicker.scss */
.dtpNewBox.active .content {
  opacity: 1;
}
/* line 171, ../src/ADM-dateTimePicker.scss */
.dtpNewBox:after, .dtpNewBox:before {
  content: '';
  position: absolute;
  display: block;
  background: #f7f7f7;
  height: 100%;
  width: 1em;
  top: 0;
  z-index: 0;
}
/* line 182, ../src/ADM-dateTimePicker.scss */
.dtpNewBox:after {
  left: -1em;
}
/* line 185, ../src/ADM-dateTimePicker.scss */
.dtpNewBox:before {
  right: -1em;
}
/* line 189, ../src/ADM-dateTimePicker.scss */
.dtpNewBox, .dtpNewBox .content, .dtpNewBox .calendarIcon {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
/* line 193, ../src/ADM-dateTimePicker.scss */
.dtpNewBox .content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
/* line 201, ../src/ADM-dateTimePicker.scss */
.dtpNewBox .calendarIcon {
  position: absolute;
  display: block;
  top: .3em;
  left: -.5em;
  z-index: 1;
  font-size: 1.2em;
  width: 1em;
  height: 1em;
  text-align: center;
  cursor: pointer;
  color: white;
  -moz-transform: translate(-3em, -3em);
  -ms-transform: translate(-3em, -3em);
  -webkit-transform: translate(-3em, -3em);
  transform: translate(-3em, -3em);
}
/* line 215, ../src/ADM-dateTimePicker.scss */
.dtpNewBox .calendarIcon svg {
  height: 22px;
  width: 17px;
  fill: white;
}
/* line 220, ../src/ADM-dateTimePicker.scss */
.dtpNewBox .calendarIcon.show {
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
/* line 223, ../src/ADM-dateTimePicker.scss */
.dtpNewBox .calendarIcon:after {
  content: '';
  position: absolute;
  display: block;
  top: -6px;
  left: -6px;
  width: 0;
  height: 0;
  border-top: 3em solid #fc5f2a;
  border-right: 3em solid transparent;
  z-index: -1;
}

/* line 238, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput {
  position: relative;
  display: table;
  border-collapse: separate;
}
/* line 242, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
/* line 247, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  margin: 0;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* line 267, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput input:focus {
  border-color: #66afe9;
  outline: 0;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
/* line 274, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput input {
  position: relative;
  display: block;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}
/* line 278, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput input[disabled] {
  background: white;
  color: black;
}
/* line 283, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput > .removeIcon {
  position: absolute;
  display: block;
  right: 2.1em;
  top: 0;
  z-index: 3;
  fill: #E64949;
  padding: 8px;
  cursor: pointer;
  opacity: .7;
  font-size: 1.1em;
  height: 34px;
}
/* line 295, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput > .removeIcon:hover {
  opacity: 1;
}
/* line 301, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput.open .dtp-ig .calendarIcon {
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
/* line 304, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput.open .dtp-ig .closeIcon {
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* line 310, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput.disable input[disabled] {
  background: #f2f2f2;
  color: gray;
}
/* line 314, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput.disable > .removeIcon {
  display: none !important;
}
/* line 317, ../src/ADM-dateTimePicker.scss */
.ADMdtpInput.disable .dtp-ig {
  cursor: not-allowed;
}

/* line 324, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box {
  position: absolute;
  display: block;
  width: 20em;
  max-width: 100vw;
  min-height: 20em;
  max-height: 25.25em;
  padding: 1em;
  padding-top: 0;
  border-radius: 5px;
  background: white;
  overflow: hidden;
  z-index: 9;
  -moz-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  -moz-transition: opacity 0.5s, height 0.5s;
  -o-transition: opacity 0.5s, height 0.5s;
  -webkit-transition: opacity 0.5s, height 0.5s;
  transition: opacity 0.5s, height 0.5s;
  font-size: 14px;
}
/* line 340, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box ::-moz-selection {
  background: transparent;
}
/* line 343, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box ::selection {
  background: transparent;
}
/* line 346, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box.square {
  height: 20em;
}
/* line 350, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box.square header .arrow.left {
  margin-left: -2em;
}
/* line 353, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box.square header .arrow.right {
  margin-right: -2em;
}
/* line 358, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box.square footer {
  height: 0;
}
/* line 362, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .arrow {
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 1;
  top: 0;
  fill: #797979;
  direction: ltr;
  font-size: 1em;
  width: 38px;
  height: 100%;
  padding: 0 .3em;
}
/* line 375, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .arrow.left {
  left: 0;
}
/* line 377, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .arrow.left:hover {
  margin-left: -.3em;
  fill: #d6c483;
}
/* line 382, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .arrow.right {
  right: 0;
}
/* line 384, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .arrow.right:hover {
  margin-right: -.3em;
  fill: #d6c483;
}
/* line 390, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box header {
  font-size: 1.2em;
  position: relative;
}
/* line 394, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box header .yearMonth {
  width: 100%;
  position: relative;
  display: block;
  text-align: center;
  padding: 1em 0;
  z-index: 0;
  cursor: pointer;
  color: #797979;
}
/* line 405, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .daysNames,
.ADMdtp-box .ADMdtpDays {
  width: 100%;
}
/* line 408, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .daysNames > span,
.ADMdtp-box .ADMdtpDays > span {
  width: calc(100% / 7);
  display: inline-block;
  text-align: center;
}
/* line 414, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .ADMdtpDays {
  min-height: 12em;
}
/* line 418, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box .daysNames > span {
  font-weight: bold;
  cursor: context-menu;
  color: #fc5f2a;
}
/* line 424, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer {
  height: 1.7em;
  position: relative;
  overflow: hidden;
}
/* line 428, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .today {
  position: absolute;
  display: block;
  width: 6em;
  height: 1.7em;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: .5em;
  background: transparent;
  color: #cab15c;
  border: 1px solid transparent;
  text-align: center;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  line-height: 1;
}
/* line 446, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .today:hover {
  border-color: #f2f2f2;
}
/* line 449, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .today:active {
  background: #fcfbf6;
}
/* line 453, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer {
  position: absolute;
  display: block;
  width: 5.5em;
  height: 1.7em;
  right: 0;
  overflow: hidden;
  cursor: pointer;
  border-radius: .5em;
  border: 1px solid transparent;
}
/* line 464, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer:hover {
  border-color: #f2f2f2;
}
/* line 467, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer:hover.gregorian p.jalali {
  background: #e3d6a9;
}
/* line 472, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer:hover.jalali p.gregorian {
  background: #e3d6a9;
}
/* line 477, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer:active {
  background: #fcfbf6;
}
/* line 480, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer p {
  margin: 0;
  line-height: 1.4em;
  text-align: center;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.1em 0;
  white-space: nowrap;
  color: #cab15c;
  -moz-transition: all 0.3s, -moz-transform 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -o-transition: all 0.3s, -o-transform 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -webkit-transition: all 0.3s, -webkit-transform 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  transition: all 0.3s, transform 0.3s cubic-bezier(0.680, -0.550, 0.265, 1.550);
}
/* line 493, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer p.jalali {
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
/* line 499, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer.jalali p.gregorian {
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
/* line 502, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .calTypeContainer.jalali p.jalali {
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/* line 508, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .timeSelectIcon {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  font-size: 1.5em;
  padding: 0.1em;
  margin-left: 0.2em;
  fill: #e3d6a9;
  cursor: pointer;
}
/* line 519, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .timeSelectIcon:hover {
  fill: #cab15c;
}
/* line 522, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box footer .timeSelectIcon:active {
  fill: #e3d6a9;
}
/* line 527, ../src/ADM-dateTimePicker.scss */
.ADMdtp-box hr {
  margin: .5em 0;
}

/* line 531, ../src/ADM-dateTimePicker.scss */
.ADMdtpMonths {
  position: absolute;
  display: block;
  width: 100%;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* line 538, ../src/ADM-dateTimePicker.scss */
.ADMdtpMonths.onYear span > span {
  width: calc(25% - 6px);
}
/* line 543, ../src/ADM-dateTimePicker.scss */
.ADMdtpMonths.rtl span > span {
  float: right;
}
/* line 547, ../src/ADM-dateTimePicker.scss */
.ADMdtpMonths span > span {
  width: calc(33% - 6px);
  text-align: center;
  position: relative;
  display: block;
  float: left;
  border-radius: .5em;
  background: #ebebeb;
  color: #a8a8a8;
  border: 1px solid #dedede;
  padding: 1em 0;
  margin: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: .9em;
}
/* line 565, ../src/ADM-dateTimePicker.scss */
.ADMdtpMonths span > span.selected, .ADMdtpMonths span > span:hover {
  border-color: #d2d2d2;
  background: #d2d2d2;
  color: #757575;
}

/* line 575, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears {
  color: #b9b9b9;
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 0.2em;
}
/* line 582, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears p {
  margin: 0;
  cursor: pointer;
}
/* line 586, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears p:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  border-bottom: 2px solid #ECECEC;
  top: 1em;
  left: 0;
  z-index: -1;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
/* line 597, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears p:hover {
  color: #fc5f2a;
}
/* line 599, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears p:hover:before {
  border-color: rgba(252, 95, 42, 0.2);
}
/* line 604, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears .arrow {
  margin-top: 0em;
  height: 35px;
  width: 35px;
  opacity: .7;
}
/* line 609, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears .arrow.right {
  right: 1.5em;
}
/* line 612, ../src/ADM-dateTimePicker.scss */
.ADMdtpYears .arrow.left {
  left: 1.5em;
}

/* line 617, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime {
  position: absolute;
  display: block;
  width: 100%;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* line 623, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span {
  width: 33%;
  height: 3em;
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 1.3em;
  vertical-align: middle;
  line-height: 3em;
}
/* line 632, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon {
  cursor: pointer;
}
/* line 634, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon svg {
  fill: #fc5f2a;
  height: 43px;
  position: absolute;
  display: block;
  width: 100%;
  top: 0.2em;
  left: 0;
}
/* line 645, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon:hover svg {
  fill: #fdab8f;
}
/* line 648, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon:hover:before {
  background: #f6f2e3;
}
/* line 653, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon:active svg {
  fill: #fc5f2a;
}
/* line 656, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon:active.up {
  margin-top: -3px;
}
/* line 659, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon:active.down {
  margin-bottom: -3px;
}
/* line 663, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.dtpIcon:before {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding: .8em 1em;
  border-radius: 1em;
}
/* line 669, ../src/ADM-dateTimePicker.scss */
.ADMdtpTime span.period {
  font-size: 2em;
}

/* line 676, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span {
  width: 100%;
  height: 100%;
  padding: .5em;
  border-radius: .5em;
  cursor: pointer;
  position: relative;
  display: block;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* line 685, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span.disable {
  opacity: .5;
  cursor: context-menu;
}
/* line 690, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span:not(.disable):hover {
  background: #fee3da;
  color: #d63803;
}
/* line 695, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span.selected, .ADMdtpMonths .ADMdtpDays > span > span:hover {
  background: #fc5f2a !important;
  color: white !important;
  border-radius: 5em;
}
/* line 700, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span.valid {
  background: #fff6f3;
  border-radius: 0;
}
/* line 703, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span.valid.min {
  border-top-left-radius: .5em;
  border-bottom-left-radius: .5em;
  border-left: 2px solid #febda8;
}
/* line 708, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span.valid.max {
  border-top-right-radius: .5em;
  border-bottom-right-radius: .5em;
  border-right: 2px solid #febda8;
}
/* line 716, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays > span > span.today:after {
  content: '';
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 0.7em solid #e3d6a9;
  border-left: .7em solid transparent;
}
/* line 736, ../src/ADM-dateTimePicker.scss */
.ADMdtpDays.loading > span > span {
  -moz-transform:  rotateY(90deg) translateX(-10px);
  -ms-transform:  rotateY(90deg) translateX(-10px);
  -webkit-transform:  rotateY(90deg) translateX(-10px);
  transform:  rotateY(90deg) translateX(-10px);
}
