.items{
  position: absolute;
  z-index: 1;
}

.item{
    width: 100%;
	text-align: left;
	background: #F2F2F2;
	padding: 10px;
	box-shadow: 0px 0px 5px #CCC;
}

.item.title{
	font-size: 18px;
	font-weight: bold;
}

.subtitle{
	font-size: 14px;
}
.item.active{
	background-color: #337ab7;
	color:#f2f2f2;
}

