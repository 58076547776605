// list-view
@keyframes highlight {
  0% {
    background-color: white;
  }
  10% {
    background-color: @highlight-color;
  }
  15% {
    background-color: white;
  }
  20% {
    background-color: @highlight-color;
  }
  25% {
    background-color: white;
  }
  30% {
    background-color: @highlight-color;
  }
  100% {
    background-color: white;
  }
}

.list-view {
  border-radius: @border-radius-base;
  color: @text-color;
  background-color: #ffffff;
  margin-bottom: @margin-default;
  .list-view-header {
    border-bottom: 1px solid darken(#ffffff, 10%);
  }
  .list-view-controls {
    padding: @padding-default/2;
    .meta {
      margin-right: @margin-default/2;
    }
    .list-view-loading-small {
      margin: @margin-default/3 0 0 @margin-default/3;
      font-size: @font-size-base;
    }
  }
  .list-view-body {
    background-color: darken(#ffffff, 10%);
    min-height: 60vh;
    .list-view-item {
      color: @text-color;
      border-left: 3px solid #ffffff;
      border-bottom: 1px solid darken(#ffffff, 10%);
      background-color: #ffffff;
      padding: @padding-default/1.25;
      &.highlight {
        animation: highlight 2.5s;
      }
      &:hover {
        background-color: darken(#ffffff, 2.5%);
        border-left: 3px solid darken(#ffffff, 2.5%);
      }
      &:active {
        background-color: darken(#ffffff, 5%);
        border-left: 3px solid darken(#ffffff, 5%);
      }
      &.info {
        border-left-color: @brand-info;
      }
      &.danger {
        border-left-color: @brand-danger;
      }
      &.border {
        border-left-color: @gray-light;
      }
      &.success {
        border-left-color: @brand-success;
      }
      &.warning {
        border-left-color: @brand-warning;
      }
      &.invoiced {
        border-left-color: @invoiced-color;
      }
      &.block {
        background-color: #e6e6e6;
      }
      small {
        color: @gray-light;
      }
    }
  }

  .list-view-empty {
    .jumbotron();
    color: darken(#ffffff, 25%);
    background-color: #ffffff;
    text-align: center;
    font-size: @font-size-large;
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .list-view-loading {
    color: darken(#ffffff, 25%);
    background-color: #ffffff;
    padding: @padding-default*3 0;
    text-align: center;
  }
}

.list-view-filters-wrapper {
  border-radius: @border-radius-base;
  background: white;
  padding: @padding-default/2 @padding-default/2 1px @padding-default/2;
}

.list-link {
  color: @text-color;
  background-color: #ffffff;
  &.highlight {
    animation: highlight 2.5s;
  }
  &:hover {
    background-color: darken(#ffffff, 2.5%);
  }
  &:active {
    background-color: darken(#ffffff, 5%);
  }
  small {
    color: @gray-light;
  }
}

.form-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.select-item {
  background-color: #f2f2f2 !important;
}